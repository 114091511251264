import React from 'react'

let ValuesData = [

{title: "Exellence",      
content: "We always strive to deliver value and offer the best services to our clients."
},

{title: "Integrity",      
content: "We manage our partnerships honestly and ethically. We always do what is right and take personal responsibility for our actions."
},
{title: "Teamwork",      
content: "We believe through teamwork we shall add value to our clients and meet their needs."
},
{title: "Accountability",      
content: "At all times we are accountable for all the actions and decisions we take."
}
];

export default ValuesData;