import React, {useEffect} from 'react';

import MaintCarousel from '../components/Maintenance';


const Services = () => {

useEffect(()=>{
document.title = 'Intercontinental Haulage | True Stone Mining Ltd';

},[]);


  return (
    <section className='section-cover services-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'1rem 0'

    }} >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Intercontinental Haulage</h3>
                <div className='line1'></div>
               
                  <p>We manage your mineral cargo transport from start to finish, guaranteeing efficiency and visibility in every link of the logistics chain.</p>
                 <p>Our mineral transport solutions include Land and sea transport, Handling & Storage, Customs Management, Cost, Insurance & Freight (CIF) and Transparency throughout the process. </p>
                  <p>To accompany you at each stage of the supply chain, we have a team of experts in the handling of minerals. We apply our advanced procedures and resources to help you with their optimum management, guaranteeing health and safety in all processes, as well as lower environmental impact.</p>
                 <p>We also have the most adequate machinery and equipment to guarantee safe storage of the mineral, and to protect it against shrinkage and contamination. We control all of the environmental conditions likely to affect the material, such as heat or humidity, which can alter its chemical properties.</p>
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services