import React, {useEffect} from 'react';

import MaintCarousel from '../components/PowerCarousel';


const Services = () => {

useEffect(()=>{
document.title = 'Mineral Consultancy | True Stone Mining Ltd';

},[]);


  return (
    <section className='section-cover services-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'1rem 0'

    }} >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Mineral Consultancy</h3>
                <div className='line1'></div>
               
                  <p>Our Mineral Consultancy Services include the following:</p>
                  <ul>
                    <li>Strategy & Advice.</li>
                    <li>Studies & Audits.</li>
                    <li>Refinery Design, Engineering, Resources & Monitoring.</li>
                    <li>Compliance & Authorizations.</li>
                  </ul>
                
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services