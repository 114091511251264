import React, {useEffect} from 'react';
import BuildingsCarousel from '../components/BuildingsCarousel'
const Services = () => {

useEffect(()=>{
document.title = 'Mining Services | True Stone Mining Ltd';

},[]);


  return (
    <section className='section-cover services-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'1rem 0'

    }} >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Mining Services</h3>
                <div className='line1'></div>
               
                  <p><strong>General Mining Services</strong> include underground and surface drilling, blasting, mineral assaying (geochemical and precious metals analysis), sample preparation, underground mining, exploration drilling, loading and hauling services, handheld mining, and shotcrete/fibrecrete services. This last type is a specialized form of concrete service that prevents smaller rocks from working loose from a rock face, replacing the conventional meshing and bolting methods.</p>
                  <p><strong>Drilling Services</strong> include diamond head drilling, top hammer drilling, down-hole hammer drilling, and in-the-hole (ITH) drilling.</p>
                  <p><strong>Project Management Services</strong> include analysis, consulting, contracting, and full management of development projects.</p>
                  <p><strong>Mine Engineering Services</strong> consist of open pit mine design, underground mine design, mine planning, capital cost estimating, and operating cost estimating.</p>
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <BuildingsCarousel></BuildingsCarousel>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services