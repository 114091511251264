import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaTasks,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = 'True Stone Mining Ltd';

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
     <section className='section-cover section-cover1' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/13.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'6rem 0'

    }}>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Who We Are</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description goal-description1'><strong>True Stone Mining Ltd</strong> is a mineral consultancy company and a prominent mineral dealer in Uganda, specializing in precious metals and minerals especially gold, diamond, silver, platinum, gem stones, emerald, sapphire, ruby stones, amethyst, topaz, cobalt, copper, coltan and lithium.Our range of services include: Mining, International Haulage, Mineral Consultancy, Advocacy of Minerals, Gold Value Increasing, among others. Our consultants have in-depth technical and managerial knowledge and experience about major minerals.
                 <br></br> <br></br> We provide professional and reliable consulting services that provide solutions to the mining and minerals industrys' challenges. Our client-centered strategy incorporates technical expertise at every stage of the mining lifecycle, from resource exploration to appraisal, development, and operations. We also do mine closure and reclamation.

</p>
                     </div>

<Link to="about-us" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               {/* <GoalsCarousel></GoalsCarousel> */}
               <img src='images/18.jpg' className='scope-image'></img>

           </article>




       </div>

  </section>
    {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>The Eight Investments Company Limited</strong> is a consulting company established in July 2013 and registered under the laws of the Republic of Uganda. We provide wide-ranging services geared toward creating value for our clients and at the same time contributing the development of their businesses and the country as a whole. Our service product range includes conducting research, right from problem identification to dissemination of results, baseline studies, monitoring and evaluation, capacity building, to mention a few. We pride in growing with our clients. </p>
<p className='home-p'>We live a very dynamic world, where the outcomes of tomorrow largely become a mystery in the absence of safeguards. Even the existence of safeguards is not a full guarantee against the tremors of the unpredictability that will materialize in the future. For this purpose, there is every reason for every individual, business, nations and the world at large to continuously work for a better tomorrow. We want to play a part in your efforts to make tomorrow a better day. At the T8 Consults we have at our disposal a dynamic group of professionals ready to offer solutions to your most pressing problems in consulting services and ensure a better tomorrow.</p>
<p className='home-p'>We have assembled a team of proven professionals working both in
the government and private sector. Our expertize ranges from
Economics, Management services, Budgeting, Project Management,
Accounting, Social Work, Strategy Development and Management
and Tax Professionals.</p>

 
        </div>

    </section> */}
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.828), rgba(35, 31, 32, 0.828)), url("./images/13.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'6rem 0'

    }}>
          <div className='quote-content'>
            {/* <h2 className='quote-h3'>Our Pledge</h2> */}
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We highly value respect, trust, transparency and the willingness to serve our clients. Therefore, we hire people who demonstrate these values.
</p>
       <div className='pledge-line pledge-line1'></div>
        </div>
    </section>
    


   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'> <div className='line2'></div></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Passion</h3>
          <p>We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>We exhibit the highest level of professionalism in all our dealings! We also comply with stringent Health and Safety regulations, which are essential both to our clients and the safety of our workforce.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers></span>
          </div>
          <h3 className='home-service-title'>Experienced Team</h3>
          <p>
           Our team is fully conversant with current planning laws and their experience and expert advice allows clients to make informed choices about the design of their projects.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Certifications</h3>
          <p>As a company, we are registered with the relevant authorities and our entire workforce holds the appropriate qualifications and certifications.</p>
        </div>


        

        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div>

        
    
    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home