import React, {useEffect} from 'react'

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | True Stone Mining Ltd';

},[]);
  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.828), rgba(35, 31, 32, 0.828)), url("./images/1.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='home-h2 home-service-h2 goals-h2 services-h2'>Gallery</h2>
              <div className='line1 line1-a line1-b'></div>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We highly value respect, trust, transparency and the willingness to serve our clients. Therefore, we hire people who demonstrate these values.
</p>
       <div className='pledge-line pledge-line1'></div> */}
        </div>
    </section>
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Site Videos</h2>
      <div className='content-container gallery'>
 <article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
          <video className='gallery-video' controls>
  <source src="./videos/v1.mp4" type="video/mp4"></source>
 
</video>
           {/* <p></p> */}
            </article>
            <article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
          <video className='gallery-video' controls>
  <source src="./videos/v2.mp4" type="video/mp4"></source>
 
</video>
           {/* <p></p> */}
            </article>
<article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
          <video className='gallery-video' controls>
             <source src="./videos/v3.mp4" type="video/mp4"></source>
          </video>
           {/* <p></p> */}
  </article>
<article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
          <video className='gallery-video' controls>
             <source src="./videos/v4.mp4" type="video/mp4"></source>
          </video>
           {/* <p></p> */}
</article>
{/* <article className='gallery-content'>
          <img src='./images/u1.jpg' className='gallery-image' alt='image'></img>
          <video className='gallery-video' controls>
             <source src="./videos/v5.mp4" type="video/mp4"></source>
          </video>
           <p></p>
  </article> */}
      </div>

    </section>
    </>
  )
}

export default Gallery