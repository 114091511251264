import React, {useEffect} from 'react';
import BuildingsCarousel from '../components/BuildingsCarousel'
const Services = () => {

useEffect(()=>{
document.title = 'Buying & Selling Minerals | True Stone Mining Ltd';

},[]);


  return (
    <section className='section-cover services-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'1rem 0'

    }} >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Buying & Selling Minerals</h3>
                <div className='line1'></div>
               
                  <p>We buy raw gold, diamond and other minerals from local miners within our region and export to the international market.</p>
                  
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <BuildingsCarousel></BuildingsCarousel>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services