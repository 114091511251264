import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCheck, FaTrain} from 'react-icons/fa';
import {GiMining,GiCargoShip,GiMiningHelmet,GiGoldMine,GiTakeMyMoney} from 'react-icons/gi';
import {BsFileBarGraph} from 'react-icons/bs';
import {SiMinetest} from 'react-icons/si';
import {GoMegaphone} from 'react-icons/go';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Services = () => {

useEffect(()=>{
document.title = 'Our Services | True Stone Mining Ltd';

},[]);


  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/12.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='home-h2 home-service-h2 goals-h2 services-h2'>Our Services</h2>
              <div className='line1 line1-a'></div>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We highly value respect, trust, transparency and the willingness to serve our clients. Therefore, we hire people who demonstrate these values.
</p>
       <div className='pledge-line pledge-line1'></div> */}
        </div>
    </section>
    
    
   <section className='home-service-cover services-container-a'>
    
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
     
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiMining></GiMining></span>
          </div>
          <h3 className='home-service-title'>Mining</h3>
          <p>We extract minerals, metals, and other materials from the earth for industrial and commercial use. Our mining services include General Mining Services, Drilling Services, Project Management Services & Mine Engineering Services</p>
          <Link to="/mining" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiCargoShip></GiCargoShip></span>
          </div>
          <h3 className='home-service-title'>Intercontinental Haulage</h3>
          <p>As experts in clearing and forwarding of minerals, at True Stone Mining Ltd we know what’s needed to transport and logistics of minerals. That’s why we offer you an integral and custom logistics service.</p>
                    <Link to="/intercontinental-haulage" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiMiningHelmet></GiMiningHelmet></span>
          </div>
          <h3 className='home-service-title'>Mineral Consultancy</h3>
          {/* <p>Our knowledgeable and experienced consultants develop innovative, practical solutions to meet our clients’ unique needs and financial objectives throughout the mining lifecycle...</p> */}
          <p>We develop innovative, practical solutions to meet our clients’ unique needs and financial objectives throughout the mining lifecycle. We provide technical advice for all commodities.</p>
           <Link to="/mineral-consultancy" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div>
{/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><BsFileBarGraph></BsFileBarGraph></span>
          </div>
          <h3 className='home-service-title'>Gold Value Increasing</h3>
          <p></p>
          <Link to="#" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div> */}

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiGoldMine></GiGoldMine>  </span>
          </div>
          <h3 className='home-service-title'>Gold Smelting</h3>
          <p>Our expertise allows us to convert gold according to the client's request, while assuring the highest degree of quality and accuracy. Our state of the art facilities coupled with our expertise enable us to...</p>
          <Link to="/gold-smelting" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div>


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><SiMinetest></SiMinetest></span>
          </div>
          <h3 className='home-service-title'>Mineral Private Testing</h3>
          <p></p>
          <Link to="#" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div> */}

            <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiTakeMyMoney></GiTakeMyMoney></span>
          </div>
          <h3 className='home-service-title'>Buying & Selling Minerals</h3>
          <p>We trade in Diamonds, Gemstones and high value metals such as Gold, Coltan and Lithium. Time and trust has given us clients from across the world. We supply legit goods and provide services on steady contracts with various...</p>
          <Link to="/buying-selling-minerals" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div>

          {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTrain></FaTrain></span>
          </div>
          <h3 className='home-service-title'>Importing & Exporting Minerals</h3>
          <p></p>
          <Link to="#" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div> */}

        
          {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GoMegaphone></GoMegaphone></span>
          </div>
          <h3 className='home-service-title'>Advocacy for Minerals</h3>
          <p></p>
          <Link to="#" className='home-btn' onClick={scrollFunc}>
         Read More
         </Link>
        </div> */}

        
    
    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


    </div>



  </section>

    </>
  )
}

export default Services