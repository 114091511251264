import React, {useEffect} from 'react'
import VisionCarousel from '../components/ContactCarousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | True Stone Mining Ltd';

},[]);


  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.828), rgba(35, 31, 32, 0.828)), url("./images/14.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='home-h2 home-service-h2 goals-h2 services-h2'>About Us</h2>
              <div className='line1 line1-a line1-b'></div>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We highly value respect, trust, transparency and the willingness to serve our clients. Therefore, we hire people who demonstrate these values.
</p>
       <div className='pledge-line pledge-line1'></div> */}
        </div>
    </section>

     
    <section className='section-cover who-we-are-cover' >
      {/* <h2 className="home-h2 wwa-h2">Who We Are</h2> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      {/* <p className='about-intro-p'></p> */}
                          <h3 className='title-h3'>Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To be a reputable mining company known for excellence, sustainable development and value addition.</p>
                          
                          <h3 className='title-h3'>Mission</h3>
                          <div className='vm-line'></div>
                          <p>To apply the best practices in the mining industry while protecting the health of our employees and the environments in which we work.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                       <VisionCarousel></VisionCarousel>
                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div>
          </div>
    </section>
    

    </>
  )
}

export default WhoWeAre