import React, {useEffect} from 'react';

import MaintCarousel from '../components/RoadCarousel';


const Services = () => {

useEffect(()=>{
document.title = 'Gold Smelting | True Stone Mining Ltd';

},[]);


  return (
    <section className='section-cover services-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.628), rgba(35, 31, 32, 0.628)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'1rem 0'

    }} >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Gold Smelting</h3>
                <div className='line1'></div>
               
                  <p>We use an electric induction furnace to smelt gold. We convert big amounts of gold into grain or smaller category bars of various sizes and purity levels using this ecologically friendly technology.</p>
                
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services