import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Our Team | DLEVIS GENERAL INVESTMENTS LTD';

},[]);



  return (
    <>
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Team</h2>
          <p className='clients-para'>Organizational Chart</p>
  
    </section>
    {/* <section className='section-cover certifications-cover' >
      <div className='all-certs'>
        <img src='images/9001.jpg' className='certification-image'></img>
 <img src='images/14001.jpg' className='certification-image'></img>
 <img src='images/45001.jpg' className='certification-image'></img>
    </div>
    </section> */}
    <section className="cert-cover">
    
        <div>
          <img src='images/oc.jpg' className='certification-image'></img>
        </div>

    </section>
    </>
  )
}

export default Clients