import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(35, 31, 32, 0.828), rgba(35, 31, 32, 0.828)), url("./images/1.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='about-us' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='mining' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Mining</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='intercontinental-haulage' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Intercontinental Haulage</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='mineral-consultancy' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Mineral Consultancy</p></Link> 
                        </div>
                        

                           <div className='footer-link'>
                              <Link to='gold-smelting' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gold Smelting</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='buying-selling-minerals' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Buying & Selling Minerals</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='gallery' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link> 
                        </div>

                    </div>

             </div>


             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Address</h3>
                      
<p>Plot 222, Kaabong District, Karamoja Region - Uganda</p>
                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                       +256 761 053 638 <br></br> +256 766 850 601

                      </p>

                    </div>

                   
                      

             </div>

          <div className='footer-inner-content'>


                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  <p>info@truestoneminingltd.com</p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; True Stone Mining Ltd 
                 
                   </p>
          </div>
    </section>
  )
}

export default Footer